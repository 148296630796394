<template>
    <div class="table">
        <!-- 头部 -->
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <!-- 表格内容 -->
        <div class="container">
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="活动名称:">
                    <el-input v-model="queryParams.name" placeholder="请输入活动名称" clearable></el-input>
                </el-form-item>
                <el-form-item label="活动状态:">
                    <el-select v-model="queryParams.state" placeholder="请选择活动状态" clearable @change="changeQuery">
                        <el-option label="停用" :value="0"></el-option>
                        <el-option label="活动结束" :value="3"></el-option>
                        <el-option label="活动中" :value="2"></el-option>
                        <el-option label="等待开始" :value="1"></el-option>
                    </el-select>
                </el-form-item>
                <div style="float: right">
                    <el-button type="primary" @click="changeQuery()">搜索</el-button>
                </div>
            </el-form>
            <div class="action">
                <el-button type="primary" @click="add()">新增储值</el-button>
            </div>
            <el-table v-loading="loading" :data="pageInfo.list" element-loading-text="数据加载中" stripe border ref="multipleTable">
                <el-table-column prop="id" label="id" width="150" align="center"></el-table-column>
                <el-table-column prop="name" label="活动名称" align="center" width="300"></el-table-column>
                <el-table-column label="储值金额" prop="stored_price" align="center" width="200"></el-table-column>
                <el-table-column label="赠送金额" prop="gift_price" align="center" width="200"></el-table-column>
                <el-table-column label="开始时间" prop="ctime" width="200" align="center"></el-table-column>
                <el-table-column prop="etime" label="结束时间" width="200" align="center"></el-table-column>
                <el-table-column label="活动状态" prop="state" align="center">
                    <template slot-scope="scope">
                        <el-tag type="primary" v-if="scope.row.state === 2">活动中</el-tag>
                        <el-tag type="info" v-if="scope.row.state === 1">等待开始</el-tag>
                        <el-tag type="info" v-if="scope.row.state === 3">活动结束</el-tag>
                        <el-tag type="primary" v-if="scope.row.state === 0">停用</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="170" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="text" icon="el-icon-edit" @click="edit(scope.$index, scope.row)">编辑</el-button>
                        <!-- <el-button v-if="scope.row.state === 2" type="text" class="red" @click="state(scope.row, 1)">下线
                        </el-button>
                        <el-button v-if="scope.row.state === 1" type="text" class="green" @click="state(scope.row, 2)">
                            上线</el-button> -->
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <Pagination v-bind:pageInfo="pageInfo" v-on:refreshPageSize="refreshPageSize" v-on:refreshPageNum="refreshPageNum" />
        </div>
        <!-- 新增 编辑 -->
        <el-dialog :title="title" :visible.sync="addVisible" width="600px" v-if="addVisible" class="dialog_box">
            <el-form ref="form" :model="form" label-width="90px" :rules="rules" label-position="left">
                <el-form-item label="活动名称 " prop="name">
                    <el-input v-model="form.name" placeholder="请输入活动名称"></el-input>
                </el-form-item>
                <el-form-item label="储值金额" prop="stored_price">
                    <el-input v-model="form.stored_price" placeholder="请输入储值金额"></el-input>
                    <span style="font-size:14px; margin-left:10px;">元</span>
                </el-form-item>
                <el-form-item label="赠送金额" prop="gift_price">
                    <el-input v-model="form.gift_price" placeholder="请输入赠送金额"></el-input>
                    <span style="font-size:14px; margin-left:10px;">元</span>
                </el-form-item>
                <el-form-item label="起止时间" prop="time">
                    <el-date-picker ref="drawDate" :picker-options="pickerOptions" style="margin-left: 5px;" v-model="form.time" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="状态" prop="state">
                    <el-radio-group v-model="form.state">
                        <el-radio :label="2">活动中</el-radio>
                        <el-radio :label="0">停用</el-radio>
                        <el-radio :label="3">活动结束</el-radio>
                        <el-radio :label="1">等待开始</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveData">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 删除 -->
        <el-dialog title="是否删除数据" :visible.sync="removeVisible" width="300px" center>
            <div class="del-dialog-cnt">删除后数据无法恢复，是否确认删除？</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="removeVisible = false">取 消</el-button>
                <el-button type="primary">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 上线、下线提示框 -->
        <el-dialog title="提示" :visible.sync="confirmVisible" width="300px" center>
            <div class="del-dialog-cnt">{{ confirmContent }}</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="confirmVisible = false">取 消</el-button>
                <el-button type="primary" @click="stateData">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import loadEvents from '@/utils/loading'
import Url from '@/components/Url';
import { request } from '@/common/request';
import { deal } from '@/common/main';
let loads = new loadEvents();
export default {
    name: 'seller',
    components: {
        Breadcrumb,
        Pagination,
        Url
    },
    data() {
        return {
            multiple: false,
            imageUrl: '',
            header: {},
            menuList: ['营销', '储值活动'],
            queryParams: {
                ctime: '',
                etime: '',
                page: 1,
                pagesize: 10
            },
            //验证规则
            rules: {
                name: [{
                    required: true,
                    message: '活动名称不能为空',
                    trigger: 'blur'
                }],
                stored_price: [{
                    required: true,
                    message: '储值金额不能为空',
                    trigger: 'blur'
                },
                {
                    pattern: '^(([1-9]{1}\\d*)|([0]{1}))(\\.(\\d){0,2})?$',
                    message: "只能输入整数或小数点后两位数字"
                }],
                gift_price: [{
                    required: true,
                    message: '赠送金额不能为空',
                    trigger: 'blur'
                },
                {
                    pattern: '^(([1-9]{1}\\d*)|([0]{1}))(\\.(\\d){0,2})?$',
                    message: "只能输入整数或小数点后两位数字"
                }],
                time: [{
                    required: true,
                    message: '时间不能为空',
                    trigger: 'blur'
                }],
                state: [{
                    required: true,
                    message: '状态不能为空',
                    trigger: 'blur'
                }],
            },
            loading: false,
            pageInfo: {},
            addVisible: false,
            removeVisible: false,
            confirmVisible: false,
            confirmContent: '',
            title: '加载中',
            mode: 'add',
            form: {
                state: 2
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            }
        };
    },
    created() {
        this.header.token = localStorage.getItem('token');
        this.header.platform = 'admin';
        this.loadData();
    },
    methods: {
        // 储值活动列表
        loadData() {
            this.loading = true;
            request.get('/wallet/activity/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.pageInfo.list = ret.data.list;
                    this.pageInfo.list.map(function (val) {
                        val.ctime = deal.timestamp(val.ctime, 'unix');
                        val.etime = deal.timestamp(val.etime, 'unix');
                        return val;
                    })
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //分页
        changeQuery() {
            this.queryParams.page = 1;
            this.loadData();
        },
        refreshPageSize(val) {
            this.queryParams.pagesize = val;
            this.loadData();
        },
        refreshPageNum(val) {
            this.queryParams.page = val;
            this.loadData();
        },
        //新增
        add() {
            this.mode = 'add';
            this.title = '新增储值';
            this.form = {
                state: 2
            };
            this.multiple = true
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        //编辑
        edit(index, row) {
            this.mode = 'edit';
            this.title = '编辑储值';
            this.multiple = false
            this.current = row.id;
            const item = this.pageInfo.list[index];
            let time = [item.ctime, item.etime]
            //处理时间
            this.form = Object.assign({}, item);
            this.$set(this.form, 'time', time);
            this.form.stored_price = this.form.stored_price + ''
            this.form.gift_price = this.form.gift_price + ''
            this.addVisible = true;
            if (this.$refs.form) {
                this.$refs.form.clearValidate()
            }
        },
        //状态
        state(row, state) {
            if (state == 2) {
                this.confirmContent = '确定是否上线?';
            } else {
                this.confirmContent = '确定是否下线?';
            }
            this.form = Object.assign({}, row);
            this.form.state = state;
            this.form.ctime = deal.timestamp(this.form.ctime);
            this.form.etime = deal.timestamp(this.form.etime);
            this.confirmVisible = true;
        },
        //增加编辑
        saveData() {
            if (this.$refs.form) {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        var url = this.mode == 'add' ? '/wallet/activity/add' : '/wallet/activity/edit';
                        this.form.ctime = deal.timestamp(this.form.time[0]);
                        this.form.etime = deal.timestamp(this.form.time[1]);
                        if (this.mode === 'add') {
                        }
                        //处理时间
                        console.log(5555, this.form)
                        request.post(url, this.form).then(ret => {
                            if (ret.code == 1) {
                                this.addVisible = false;
                                this.loadData();
                                this.$message.success((this.mode == 'add' ? '新增' : '编辑') +
                                    '成功');
                                this.$refs.form.clearValidate()
                                this.imageUrl = '';
                            } else {
                                this.$message.error(ret.msg);
                            }
                        });
                    } else {
                        this.$message.error('请补全信息');

                        return false;
                    }
                });
            };
        },
        // 确定下线
        stateData() {
            request.post('/wallet/activity/edit', this.form).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        }
    }
};
</script>
<style scoped>
.red {
    color: #ff0000;
}

.green {
    color: green;
}
/* 对话框样式 */

.dialog_box >>> .el-dialog__body {
    padding: 20px 30px 20px 60px;
}

.dialog_box >>> .el-dialog__header {
    padding: 30px 30px 10px;
}

.dialog_box .el-form-item--small.el-form-item {
    margin-bottom: 20px;
}
</style>